import React from 'react'
// import _get from 'lodash/get'
import { graphql } from 'gatsby'
// import { ChevronLeft } from 'react-feather'

import Content from '../components/Content'
import Layout from '../components/Layout'
import './SinglePost.css'
import Gallery from '../components/Gallery'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Image from '../components/Image'
// import styled from 'styled-components'

// const VerticalTabs = styled(Tabs)`
//   display: flex;
// `;

// const VerticalTabList = styled(TabList)`
//   display: flex;
//   flex-direction: column;
//   width: 10vw
// `;

export const SinglePostTemplate = ({ title, body, images }) => (
  <main>
    <article
      className="SinglePost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container">
        {/* <Link className="SinglePost--BackButton" to="/blog/">
          <ChevronLeft /> BACK
        </Link> */}
        <div className="SinglePost--Content relative">
          {title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {title}
            </h1>
          )}

          <div className="Product-Screenshot">
            {images === null ? (
              <div className="no-image">No Images Found</div>
            ) : (
              <Tabs className="allTabs">
                {images.map((items, index) => (
                  <TabPanel key={index} style={{ textAlign: 'center' }}>
                    <Tab>
                      <Image
                        src={items.image}
                        alt={items.alt}
                        className="mainImage"
                      />
                      {/* <img src={items.image} alt={items.alt} /> */}
                    </Tab>
                  </TabPanel>
                ))}
                <TabList
                  className="allTabsList"
                >
                  {images.map((items, index) => (
                    <Tab key={index}>
                      <div>
                        <Image
                          src={items.image}
                          alt={items.alt}
                          className="otherImages"
                        />
                      </div>
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            )}
          </div>

          {/* <div className="SinglePost--Meta">
            {date && (
              <time
                className="SinglePost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={date}
              >
                {date}
              </time>
            )}
            {categories && (
              <Fragment>
                <span>|</span>
                {categories.map((cat, index) => (
                  <span
                    key={cat.category}
                    className="SinglePost--Meta--Category"
                  >
                    {cat.category}
                    {index !== categories.length - 1 ? ',' : ''}
                  </span>
                ))}
              </Fragment>
            )}
          </div> */}

          {/* <section className="section"> */}
          {/* {images && <div className="container"> */}
          {/* <h2>Our gallery component</h2> */}
          {/* <Gallery images={images} /> */}
          {/* </div>} */}
          {/* </section> */}

          {/* {title && (
            <h1 className="SinglePost--Title" itemProp="title">
              {title}
            </h1>
          )} */}

          <div className="SinglePost--InnerContent">
            <Content source={body} />
          </div>

          {/* <div className="SinglePost--Pagination">
            {prevPostURL && (
              <Link
                className="SinglePost--Pagination--Link prev"
                to={prevPostURL}
              >
                Previous Post
              </Link>
            )}
            {nextPostURL && (
              <Link
                className="SinglePost--Pagination--Link next"
                to={nextPostURL}
              >
                Next Post
              </Link>
            )}
          </div> */}
        </div>
      </div>
    </article>
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  // const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        images={post.frontmatter.images}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
        images {
          alt
          image
          title
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
